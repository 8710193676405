@import './../../../theme/theme';
@import './ProjectSnippet.module.scss';
@import './ProjectDetails.module.scss';

.container,
.embedContainer {
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  // padding-bottom: 60px;
  z-index: 12;
}

.container {
  margin-top: 50vh;
}

.embedContainer {
  margin-top: 0px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .sidebar {
    transition: transform 0.5s ease-in-out;

    &.mobile-hidden {
      transform: translateY(60vh);
      max-height: 0;
      overflow: hidden;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.embedContainer::-webkit-scrollbar {
  display: none;
}

.MuiButton-root.toggleButton {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px 13px 0px 0px;
  height: 50px;
  width: 100%;
  overflow: hidden;

  &.header--mobile {
    height: 66px;
    padding-top: 16px;
  }
}

.headerText {
  margin: 0;
  padding-left: 20px;
  font-weight: 600;
}

.tabButtonContainer {
  flex-grow: 1;
  display: flex;
}

.tabButton {
  background-color: transparent;
  border: none;
  margin-left: 16px;
  margin-top: 18px;
}

.tabButton:hover {
  cursor: pointer;
}

.tabButtonText {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  font-weight: bold;
  color: $primaryFontColor;
  margin-bottom: 10px;
}

.tabButtonSelected {
  color: $primaryColor;
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  font-weight: bold;
  margin-bottom: 10px;
}

.tabButtonSelectedIndicator {
  height: 3px;
  background-color: $primaryColor;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  align-self: flex-end;
}

.headerSearchMode {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $backgroundColor;
  border-radius: 13px 13px 0px 0px;
  width: 100%;
  height: 50px;
}

.searchIcon {
  margin-left: 6px;
  padding: 10px;
  font-size: $fontXSmall;
  margin-right: 6px;
}

.searchIcon:hover {
  cursor: pointer;
}

.searchInput {
  flex-grow: 3;
  color: $primaryFontColor;
}
.searchInput > input {
  background-color: rgb(255, 0, 0);
}
.cancelIcon {
  margin-right: 6px;
  padding: 10px;
}

.cancelIcon > svg {
  width: 18px;
}

.cancelIcon:hover {
  cursor: pointer;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 100px;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  > div {
    margin-top: 20px;
    position: relative;
  }
}

.projectsContainer::-webkit-scrollbar {
  width: 6px;
}

.projectsContainer::-webkit-scrollbar-track {
  background-color: $dividerColor;
}

.projectsContainer::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
}

.popupProject {
  margin-top: 0px;
  cursor: pointer;
  z-index: 5;
  background-color: $backgroundColor;
}

.allProjectsContainer {
  padding-bottom: 12px;
}

.projectNotFound {
  font-family: $primaryFontFamily;
  text-align: center;
  padding: 0px 20px;
}
.project {
  color: $primaryFontColor;
}

.projectNotFoundImage {
  width: 100%;
}

.avoidPointerEvents {
  display: block;
  height: 50vh;
  z-index: 999;
  // pointer-events: none;
}

.openMap {
  padding: 12px 20px;
  border-radius: 100px;
  background-color: $primaryColor;
  color: $light;
  position: absolute;
  bottom: 90px;
  right: 0;
  left: 0;
  // max-width: 50px;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.openMap > svg {
  width: 20px;
  margin-right: 5px;
}

.dragBar {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  background-color: #c5c5c5;
  height: 5px;
  border-radius: 100px;
}

@include smTabletView {
  .allProjectsContainer > div > div {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
  }

  .container,
  .embedContainer {
    padding-bottom: 0px;
  }
  .embedContainer {
    margin-top: 40vh;
  }
  .projectsContainer {
    padding-bottom: 80px;
  }
}

@include mdTabletView {
  .container,
  .embedContainer {
    overflow: visible;
    // padding-bottom: 0px;
    width: fit-content;
    // z-index: 2;
    bottom: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    z-index: 2;
  }

  .container {
    padding-left: 14px;
    margin-top: 80px;
    padding-top: 20px;
  }

  .embedContainer {
    padding-top: 20px;
    margin-top: 0px;
    padding-left: 14px;
    height: 100vh;
    overflow-y: scroll;
  }

  .sidebar {
    padding: 10px 10px 0;

    .mobile-hidden {
      height: auto;
    }
  }

  // .container::-webkit-scrollbar {
  //   display: none;
  // }

  .allProjectsContainer > div > div {
    display: block;
  }
  .cardContainer {
    position: relative;
    top: 30px;
    left: 24px;
  }
  .projectsContainer {
    margin-top: 5px;
    width: 360px;
    overflow-y: hidden;
    padding-bottom: 16px;
  }
  .header {
    width: 360px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  }

  .headerSearchMode {
    width: 360px;
  }

  .avoidPointerEvents {
    display: none;
  }

  .openMap {
    display: none;
  }
}
@media screen and (max-height: 315px), (max-width: 486px) {
  .embedContainer {
    margin-top: 40vh;
  }
  .MuiButton-root.toggleButton {
    bottom: 62px;
  }
}
:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}

.impersonationTop {
  margin-top: 42px;
}