@import '../../../theme/theme';

.mapContainer,
.onlymapContainer {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  
}

.mapContainer > div:first-child,
.onlymapContainer > div:first-child {
  height: 100% !important;
  width: 100% !important;
}

.marker {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 10%;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 40%;
  margin: -20px 0 0 -20px;
  box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  &.conservationMarker {
    background: #1ea9e4;
  }
}

.markerType {
  transform: rotate(45deg);
  width: 60px;
  height: 40px;
  margin-left: -6px;
  margin-top: -4px;
}

.mapNavigation {
  position: absolute;
  right: 20px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div {
    position: relative !important;
  }
}

.layerToggle {
  width: 30px;
  height: 30px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  background-color: $backgroundColor;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 150px;
}

.projectControls {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  bottom: auto;
  top: 30px;
  width: max-content;
  height: 30px;
  padding: 5px 20px;
  background-color: #f5f5f5;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  cursor: pointer;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $primaryFontFamily;
  z-index: 10;
}

.projectControls > div > svg {
  height: 16px;
  padding: 0 8px;
}

.projectControlText {
  font-size: $fontSmall;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  min-width: 100px;
}

.mapContainer .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

.projectSitesButton,
.embed_projectSitesButton {
  position: fixed;
  right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
  svg {
    width: 20px;
  }

  &.projectSitesButton--reduce-right-offset {
    right: 60px;
  }
}

.projectSitesButton {
  top: 100px;
}

.embed_projectSitesButton {
  top: 30px;
}

.dropdownContainer,
.embed_dropdownContainer {
  position: fixed;
  z-index: 11;
  right: 100px;

  &.dropdownContainer--reduce-right-offset {
    right: 60px;
  }
}

.dropdownContainer {
  top: 100px;
}

.embed_dropdownContainer {
  top: 30px;
}

.projectSitesDropdown,
.embed_projectSitesButton {
  z-index: 100;
}

.polygonIcon {
  position: absolute;
  z-index: 101;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.exploreButton,
.embed_exploreButton {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 10px;
  padding: 9px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
}

.exploreButton {
  top: 100px;
}

.embed_exploreButton {
  top: 30px;
  flex-direction: row;
}
.exploreButton > svg,
.embed_exploreButton > svg {
  width: 17px;
}

.exploreButton > svg > path,
.embed_exploreButton > svg > path {
  fill: $primaryFontColor;
}

.forestInfo {
  color: $primaryFontColor;
}
.exploreText {
  padding-left: 4px;
  margin: 0;
  font-size: $fontSmall;
  color: $primaryFontColor;
}

.exploreCaption {
  border-top: 2px solid $dividerColor;
  padding-top: 15px;
  width: 200px;
  font-size: $fontXXSmall;
  text-align: center;
  color: $primaryFontColor;
}

.exploreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10px;
  cursor: pointer;
}

.exploreInfo > svg > path {
  fill: $primaryFontColor;
}

.deforestionSlider > div {
  border: none;
}

.deforestionSlider > div > div > ul > li > div > div > header {
  display: none !important;
}

.deforestionSlider > div > div {
  width: 100%;
}

.deforestionSlider > div > div > ul > li {
  width: 100%;
}

.deforestionSlider > div > div > ul > li > div {
  padding: 0px 4px 10px !important;
}

.deforestionSlider > div > div > ul > li > div > div > div > div > button {
  background-color: transparent;
}

.infoModal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.infoExpanded {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 375px;
  max-width: 100%;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  animation: fade_in_show 0.5s;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.infoTitle {
  font-weight: 600;
  color: $primaryFontColor;
}

.infoContent {
  width: 100%;
  font-size: $fontXSmall;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoContent > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
}
.infoContent > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $primaryFontColor;
}

.infoContent > div > p {
  font-size: $fontXXSmall;
}

.infoContent > a > svg {
  width: 20px;
  margin-right: 6px;
}

.infoContent > div > div {
  width: 100%;
}

.infoContent > div > div > ul > li {
  width: 100%;
}

.infoContent > div > div > ul > li > div {
  padding: 15px 4px !important;
}

.infoContent > div > div > ul > li > div > div > header {
  display: none !important;
}

.reforestationScale {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  color: $primaryFontColor;
}

.reforestationScale > div {
  width: 230px !important;
  margin: 0 5px;
  height: 7px;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    rgba(126, 212, 230, 1) 20%,
    rgba(59, 0, 255, 1) 100%
  );
}
.restorationInfo {
  color: $primaryFontColor;
}

.reforestationScale > p:nth-child(1) {
  font-size: $fontXSmall;
  // color: #fadc2f;
}

.reforestationScale > p:nth-child(3) {
  font-size: $fontXSmall;
  // color: #fadc2f;
}

.openLink > svg > path {
  fill: $primaryFontColor;
}
.currentForestScale {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.currentForestScale > div {
  width: 230px !important;
  margin: 0 5px;
  height: 7px;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    $primaryColor 1.1%,
    $primaryDarkColor 98.6%
  );
}

.currentForestScale > p:nth-child(1) {
  font-size: $fontXSmall;
  color: $primaryColor;
}

.currentForestScale > p:nth-child(3) {
  font-size: $fontXSmall;
  color: $primaryDarkColor;
}

.layerLegend {
  width: 200px;
}

.infoClose {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.infoClose svg {
  width: 20px;
}

.exploreExpanded,
.embed_exploreExpanded {
  position: fixed;
  right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  animation: fade_in_show 0.5s;
  z-index: 12;
}

.embed_exploreExpanded {
  top: 30px;
}

.exploreExpanded {
  top: 20px;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.exploreToggleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  height: 48px;
  color: $primaryFontColor;
}

.exploreToggleRow svg {
  width: 15px;
}

.exploreExpanded hr {
  margin-top: 10px;
}

.exploreNote {
  padding-top: 10px;
  text-align: center;
  width: 200px;
  font-family: $primaryFontFamily;
  font-size: $fontXXSmall;
}

.exploreNote a {
  color: $primaryColor;
}

.exploreNote a:visited {
  color: $primaryColor;
}

.exploreNote a:hover {
  color: $primaryColor;
}

.lngSwitcher {
  position: fixed;
  bottom: 0px;
  right: 20px;
  font-size: 11px;
  color: $primaryFontColor;
  cursor: pointer;
  background-color: $backgroundColor;
  padding: 6px 18px 4px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-family: $primaryFontFamily;
  z-index: 11;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: visible;
  > a {
    margin-left: 10px;
    text-decoration: none;
    color: rgba($primaryFontColor, 0.75);
  }
  > span {
    margin-left: 10px;
    a {
      text-decoration: none;
      color: rgba($primaryFontColor, 0.75);
    }
  }
}

.layerSwitcher {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 20px;
  top: 100px;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
}

.layerOption {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $backgroundColor;
  width: 60px;
  height: 30px;
  border-radius: 0 !important;
  cursor: pointer;
  font-family: $primaryFontFamily;
  font-weight: 700;
}

.active {
  background-color: $primaryColor;
  color: $light;
}

.clickForDetails {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $primaryFontColor;
  background: $backgroundColor;
}

@include smTabletView {
  .projectSitesButton {
    top: 100px;
  }

  .dropdownContainer {
    top: 100px;
  }

  .exploreButton {
    top: 100px;
  }
  .exploreExpanded {
    top: 110px;
  }
  .embed_exploreExpanded {
    top: 30px;
  }
  // .infoExpanded {
  //   top: 100px;
  // }

  .layerToggle {
    top: 150px;
  }
}

@include mdTabletView {
  .mapContainer {
    padding-top: 60px;
    padding-bottom: 0px;
  }

  .exploreExpanded {
    top: 110px;
  }
  .projectSitesButton {
    top: 110px;
  }

  .projectSitesButton,
  .embed_projectSitesButton {
    right: 175px;

    &.projectSitesButton--reduce-right-offset {
      right: 70px;
    }
  }

  .dropdownContainer,
  .embed_dropdownContainer {
    right: 165px;

    &.dropdownContainer--reduce-right-offset {
      right: 70px;
    }
  }

  .dropdownContainer {
    top: 110px;
  }

  .exploreButton {
    top: 110px;
    flex-direction: row;
  }

  .layerToggle {
    top: auto;
    right: 20px;
    bottom: 125px;
  }

  .exploreButton,
  .embed_exploreButton {
    right: 20px;
  }
}

// @include lgLaptopView {
//   .projectControls {
//     left: 0px;
//   }
// }

@media screen and (max-height: 315px), (max-width: 481px) {
  .mapNavigation {
    display: none;
  }
  .projectControls {
    bottom: 80px;
  }
}

// Popover

.popover {
  position: relative;

  .popoverContent {
    position: absolute;
    z-index: 10;
    background-color: $backgroundColor;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    display: none;
    flex-direction: column;
    font-weight: 400;
  }
  &:hover,
  &:active {
    cursor: pointer;
    .popoverContent {
      display: flex;
    }
  }
}

.popupProject {
  min-width: 300px;
}

@include lgLaptopView {
  .projectSitesDropdown {
    right: 120px;
  }

  .projectControls {
    display: flex;
    top: auto;
    bottom: 30px;
    left: 350px;
  }
}

@include xsPhoneView {
  .exploreButton {
    top: 149px;
  }
}
